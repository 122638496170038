/* App.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth; /* Ensures smooth scrolling */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img[src=""] {
  display: none;
}

img:not([src]) {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Smooth scrolling for the main container */
.smooth-scroll-container {
  scroll-behavior: smooth;
  overflow-y: auto;
}

/* Red monochrome banner */
.banner {
  background-color: #ff0000; /* Bright red background */
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Loading overlay styles */
.loading-overlay.top,
.loading-overlay.bottom {
  position: fixed;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.8); /* Dark background for contrast */
  color: white;
  padding: 10px 0;
  z-index: 1000;
  animation: fadeInOut 1.5s forwards;
  pointer-events: none;
}

.loading-overlay.top {
  top: 0;
}

.loading-overlay.bottom {
  bottom: 0;
}

/* Blockquote styling */
.blockquote {
  position: relative;
  padding-left: 1em;
  border-left: 4px solid #8aa6c1;
  background-color: #f0f4f7;
  overflow: hidden;
  margin: 20px 0;
  font-style: italic;
  color: #333;
}

.blockquote::before {
  content: '';
  display: block;
  clear: both;
}

.blockquote p {
  margin: 0;
}

/* Floating image styles */
img.floating-image {
  float: right;
  margin: 0 0 10px 10px;
  shape-outside: margin-box;
  clip-path: inset(0);
}

.blockquote img.floating-image {
  margin-left: 10px;
  margin-right: 0;
  float: left;
}

/* Fade-in and fade-out animation */
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Remove left/right margins from all images */
img {
  margin-left: 0px;
  margin-right: 0px;
}

/* App link color */
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Floating Banner */
.floating-banner {
  position: fixed; /* Makes it float and stick at the top */
  top: 0; /* Fixes it to the top of the page */
  left: 0; /* Aligns to the left */
  width: 100%; /* Spans the full width of the page */
  background-color: red; /* Red background */
  color: white; /* White text */
  text-align: center; /* Centers the text */
  font-size: 24px; /* Larger text */
  font-weight: bold; /* Bold text */
  padding: 10px 0; /* Adds vertical spacing */
  z-index: 1000; /* Ensures it floats above other content */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Adds a slight shadow for visibility */
}

